import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import UserSidebar from './UserSidebar';
import { currencyOptions } from '../admin/currencyOptions';
import { toast } from 'react-toastify';
import { BiDollar } from "react-icons/bi";
import { LuGift } from "react-icons/lu";
import { RiArrowGoForwardFill, RiBarChart2Fill } from 'react-icons/ri';
import { FaBtc } from 'react-icons/fa6';
import UserNav from './UserNav';
import Loader from '../../components/Loader';
import axios from 'axios';
import LineChart from './LineChart';
import BarChart from './BarChart';
import DonutChart from './DonutChart ';
import TransactionDown from "../../assets/images/transaction-down.svg";
const UserDashboard = ({ children }) => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allTransactions, setAllTransactions] = useState([]);
    const [btcTotal, setBtcTotal] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const authData = Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : null;
    const token = Cookies.get("token");
    // Fetch Transactions
    useEffect(() => {
        const fetchTransactionsData = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/transaction/user-transactions/${authData.user._id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data?.success) {
                    const sortedTransactions = data.transactions.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setAllTransactions(sortedTransactions);

                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.log(error);

            } finally {
                setLoading(false);
            }
        };

        fetchTransactionsData();
    }, []);

    const displayedTransactions = showAll ? allTransactions : allTransactions.slice(0, 3);
    // Toggle `showAll` state
    const handleToggleShowAll = () => {
        setShowAll(!showAll);
    };

    const getCurrencyFlag = (currency) => {
        const currencyData = currencyOptions.find(option => option.value === currency);
        return currencyData ? currencyData.flag : null;
    };
    useEffect(() => {
        // Calculate total BTC amount after deducting sales and hedging fees
        const total = allTransactions.reduce((acc, transaction) => {
            if (transaction.currency === "BTC") {
                if (transaction.type === "Deposit") {
                    return acc + transaction.amount;

                } else if (transaction.type === "Sales fee" || transaction.type === "Hedging fee") {
                    return acc - transaction.amount;
                }
            }
            return acc;
        }, 0);

        setBtcTotal(total);
    }, [allTransactions]);
    return (
        <>
            <div
                className="dashboard-container"></div>
            {loading && <Loader />}
            <UserSidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {/* Main Content */}
            <div className={`dashboard-main ${sidebarOpen ? 'shifted' : ''}`}>
                <UserNav title={"Dashboard"} />
                <div className="dashboard-content" >
                    <div className="user-cards-main">

                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <RiBarChart2Fill />
                                </div>
                                <div className="dashboard-user-card-text">NAV</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2">---</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <BiDollar />
                                </div>
                                <div className="dashboard-user-card-text">Deposits</div>
                            </div>
                            <div className="dashboard-user-card-text text-center pt-2">---</div>


                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <LuGift />
                                </div>
                                <div className="dashboard-user-card-text">Redemptions</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2"> ---</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <RiArrowGoForwardFill />
                                </div>
                                <div className="dashboard-user-card-text">Returns </div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2">   ---</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <FaBtc />
                                </div>
                                <div className="dashboard-user-card-text">Wallet</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2">{btcTotal.toFixed(2)}</div>

                        </div>
                    </div>

                    <div className="my-3">
                        <div className="charts-main">
                            <div className="charts-left">
                                <div className="chart-nav">NAV Chart</div>
                                <LineChart allTransactions={allTransactions} />

                            </div>
                            <div className="charts-right">
                                <h4 className='chart-overview'>Overview</h4>
                                <BarChart allTransactions={allTransactions} />
                            </div>
                        </div>

                    </div>
                    <div className="my-3">
                        <div className="charts-main">
                            <div className="charts-left">
                                <div className="recent-activity-main">
                                    <h3 className='recent-title'>Recent Activities</h3>
                                    <div className="recent-activities-sub">
                                        <div className="tbl-main">
                                            <div className="tbl-main">
                                                <table className="simple-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Asset</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                                            <th>Type</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {displayedTransactions?.map((transaction) => (
                                                            <tr key={transaction._id} className="transaction-row">
                                                                <td>{new Date(transaction.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}, <br /> {new Date(transaction.date).toLocaleTimeString('en-GB')}</td>
                                                                <td>  {getCurrencyFlag(transaction.currency) && (
                                                                    <img
                                                                        src={getCurrencyFlag(transaction.currency)}
                                                                        alt={transaction.currency}
                                                                        style={{ width: '20px', marginLeft: '5px' }}
                                                                    />
                                                                )} {transaction.currency} </td>
                                                                <td>
                                                                    {transaction.type !== "Deposit" ? (
                                                                        <>
                                                                            {transaction.amount}
                                                                        </>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </td>

                                                                <td>
                                                                    {transaction.type === "Deposit" ? (
                                                                        <>
                                                                            {transaction.amount}

                                                                        </>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </td>

                                                                <td>{transaction.type}</td>
                                                                <td

                                                                >
                                                                    <button className={
                                                                        transaction.status === 0
                                                                            ? "trans-pending trans-btn"
                                                                            : transaction.status === 1
                                                                                ? "trans-success trans-btn"
                                                                                : "trans-failed trans-btn"
                                                                    }>
                                                                        {transaction.status === 0
                                                                            ? "Pending"
                                                                            : transaction.status === 1
                                                                                ? "Completed"
                                                                                : "Failed"}
                                                                    </button>

                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                                <div className="text-center">
                                                    <button style={{
                                                        transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)',
                                                        transition: 'transform 0.3s ease' // Smooth transition
                                                    }} onClick={handleToggleShowAll} className='border-0 bg-transparent'><img src={TransactionDown} alt="" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="charts-right">
                                <h4 className='chart-overview'>Portfolio</h4>

                                <DonutChart allTransactions={allTransactions} />

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </>
    );
};

export default UserDashboard;



