import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { FaBarsStaggered } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { IoSearchOutline } from "react-icons/io5";
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import AdminNav from './AdminNav';

const Transactions = () => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [allTransactions, setAllTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    // Fetch Transactions
    useEffect(() => {
        const fetchTransactionsData = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/transaction/transactions`);
                if (data?.success) {
                    const sortedTransactions = data.transactions.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setAllTransactions(sortedTransactions);
                    setFilteredTransactions(sortedTransactions);
                } else {
                    toast.error(data.message || ' No Transactions Found !');
                }
            } catch (error) {
                console.log(error);

            } finally {
                setLoading(false);
            }
        };

        fetchTransactionsData();
    }, []);

    // Handle Search
    useEffect(() => {
        const searchQuery = searchTerm.trim().toLowerCase();

        const filtered = searchQuery
            ? allTransactions.filter(transaction => {
                const id = transaction._id || '';
                const userName = transaction.name?.toLowerCase() || '';
                const userEmail = transaction.email?.toLowerCase() || '';
                const amount = transaction.amount.toString();
                const transactionDate = new Date(transaction.date).toLocaleString().toLowerCase(); // Format the date to a string

                return (
                    id.includes(searchQuery) ||
                    userName.includes(searchQuery) ||
                    userEmail.includes(searchQuery) ||
                    amount.includes(searchQuery) ||
                    transactionDate.includes(searchQuery)
                );
            })
            : allTransactions;

        setFilteredTransactions(filtered);
    }, [searchTerm, allTransactions]);

    // Edit Transaction Handler (Implement as needed)
    const handleEdit = (transactionId) => {
        // Navigate to edit page or open modal
        navigate(`/transactions/edit/${transactionId}`);
    };



    return (
        <div className="wrapper d-flex align-items-stretch">
            {loading && <Loader />}
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div id="content" className="px-2">
                <button type="button" id="openSidebar" onClick={toggleSidebar} className="bars-btn">
                    <FaBarsStaggered />
                </button>
                <AdminNav />

                <div className="search-add-main">
                    <div className="search-main">
                        <input
                            type="text"
                            className="search-inp"
                            placeholder="Search transactions by username or email..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="search-icon">
                            <IoSearchOutline />
                        </div>
                    </div>
                </div>

                <div className="tbl-main">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Payment Method</th>

                                <th>Status</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTransactions.length > 0 ? (
                                filteredTransactions.map(transaction => (
                                    <tr key={transaction._id}>
                                        <td>{transaction._id}</td>
                                        <td>{new Date(transaction.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}, <br /> {new Date(transaction.date).toLocaleTimeString('en-GB')}</td>
                                        <td>{transaction.name}</td>
                                        <td>{transaction.email}</td>
                                        <td className={
                                            transaction.type === "Deposit" ? "text-success" :
                                                ["Hedging fee", "Sales fee"].includes(transaction.type) ? "text-danger" : ""
                                        }>
                                            {transaction.type === "Deposit" ? "+" :
                                                ["Hedging fee", "Sales fee"].includes(transaction.type) ? "-" : ""}
                                            {parseFloat(transaction.amount).toFixed(2)} {transaction.currency}
                                        </td>


                                        <td>{transaction.type}</td>
                                        <td className=' text-capitalize'>{transaction.paymentMethod}</td>


                                        <td>
                                            <button className={`btn btn-sm ${transaction.status === 0 ? 'btn-warning' :
                                                transaction.status === 1 ? 'btn-success' :
                                                    transaction.status === 2 ? 'btn-danger' : ''}`}>
                                                {transaction.status === 0 ? 'Pending' :
                                                    transaction.status === 1 ? 'Completed' :
                                                        transaction.status === 2 ? 'Failed' : 'Unknown'}
                                            </button>
                                        </td>



                                        <td>
                                            <button className='btn btn-primary btn-sm' onClick={() => handleEdit(transaction._id)}>Edit</button>
                                        </td>

                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7">No transactions found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Transactions;
