import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutChart = ({ allTransactions }) => {
    const currencyAmounts = allTransactions.reduce((acc, transaction) => {
        if (transaction.type === 'Deposit' && transaction.currency) {
            acc[transaction.currency] = (acc[transaction.currency] || 0) + transaction.amount;
        }
        return acc;
    }, {});

    const series = Object.values(currencyAmounts);
    const labels = Object.keys(currencyAmounts);

    const chartOptions = {
        chart: { type: 'donut', height: 350 },
        labels: labels,
        dataLabels: { enabled: false }, // Hide percentages in the chart
        colors: ['#F7931A'], // Customize colors
        legend: {
            position: 'right',
            formatter: function (currency) {
                return `${currency}`;
            },
        },
    };

    return (
        <div>
            {series.length > 0 ? (
                <ReactApexChart
                    options={chartOptions}
                    series={series}
                    type="donut"
                    height={250}
                />
            ) : (
                <p>No transaction data available</p>
            )}
            {Object.entries(currencyAmounts).map(([currency, amount]) => (
                <h6 key={currency} className="bc-clr">
                    {currency} = {amount.toFixed(2)}
                </h6>
            ))}
        </div>
    );
};

export default DonutChart;
