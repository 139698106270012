import React, { useState } from 'react';
import { Modal } from 'antd';
import { IoCloudUploadOutline } from 'react-icons/io5';

const UtilityBillUploadModal = ({ visible, onClose, onFileChange, onUpload, selectedFile }) => {
    const [fileType, setFileType] = useState(null); // Store file type
    const [filePreview, setFilePreview] = useState(null); // Store preview content

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type;
            setFileType(fileType);
            onFileChange(file);

            // For image previews
            if (fileType.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFilePreview(reader.result);
                };
                reader.readAsDataURL(file);
            }

            // For PDF previews, store a blob URL
            if (fileType === 'application/pdf') {
                const blobURL = URL.createObjectURL(file);
                setFilePreview(blobURL);
            }
        }
    };

    return (
        <Modal open={visible} footer={null} onCancel={onClose}>
            <div className="upload-dropzone" onClick={() => document.getElementById('utilityBillFileInput').click()}>
                <IoCloudUploadOutline style={{ fontSize: '2em', color: '#ccc' }} />
                <p>Upload Utility Bill</p>
                <input
                    type="file"
                    id="utilityBillFileInput"
                    accept=".jpg, .png, .pdf"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
            <p>JPG, PNG, or PDF, up to 5 MB</p>

            {/* Conditional rendering for preview */}
            {fileType?.startsWith('image/') && (
                <img
                    src={filePreview}
                    alt="Utility Bill Preview"
                    style={{ width: '100px', height: '100px' }}
                />
            )}
            {fileType === 'application/pdf' && filePreview && (
                <a
                    href={filePreview}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'inline-block', marginTop: '10px', color: '#1890ff' }}
                >
                    Preview PDF
                </a>
            )}

            <div className="upload-actions">
                <button className="upload-btn" onClick={onClose}>
                    Upload
                </button>
                <button className="cancel-btn" onClick={onClose}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default UtilityBillUploadModal;
