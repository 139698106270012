import React from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const BarChart = ({ allTransactions }) => {
    const today = dayjs().utc();
    const sevenDaysAgo = today.subtract(7, 'day').startOf('day');
    let categories = [];
    let groupedData = {}; // Object to store fees grouped by date and currency

    // Prepare categories for the last 7 days
    for (let i = 0; i <= 7; i++) {
        const currentDate = sevenDaysAgo.add(i, 'day').startOf('day').format('MMM D');
        categories.push(currentDate);
    }

    // Group transactions by date and currency
    allTransactions?.forEach((transaction) => {
        const transactionDate = dayjs(transaction.date).utc().startOf('day').format('MMM D');
        const currency = transaction.currency || 'USD'; // Fallback to 'USD' if no currency is provided

        if (!groupedData[currency]) groupedData[currency] = Array(8).fill(0); // Initialize array for 8 days

        categories.forEach((date, index) => {
            if (
                transactionDate === date &&
                (transaction.type === 'Sales fee' || transaction.type === 'Hedging fee')
            ) {
                groupedData[currency][index] += transaction.amount;
            }
        });
    });

    // Prepare series for ApexCharts
    const series = Object.keys(groupedData).map((currency) => ({
        name: currency,
        data: groupedData[currency],
    }));

    const chartData = {
        series,
        options: {
            chart: {
                id: 'feesLast7Days',
                height: 350,
                type: 'bar',
                toolbar: { show: false },
                zoom: { enabled: false }
            },
            colors: ['#0E2340', '#616060'],
            dataLabels: { enabled: false },
            plotOptions: {
                bar: {
                    columnWidth: '35%',
                    borderRadius: 2,
                    dataLabels: { position: 'top' }
                }
            },
            xaxis: { type: 'category', categories },
            yaxis: {
                labels: {
                    formatter: (value) => `${Math.round(value).toLocaleString()}`
                }
            },
            tooltip: {
                y: {
                    formatter: (value, { seriesIndex }) => {
                        const currency = series[seriesIndex]?.name || 'USD';
                        return `${value.toLocaleString()} ${currency}`;
                    }
                },
                x: {
                    formatter: (val) => val
                }
            },
            legend: { show: true }
        }
    };

    return (
        <div>
            {series.some((s) => s.data.some((value) => value > 0)) ? (
                <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
            ) : (
                <p>No fee data available for the last 7 days.</p>
            )}
        </div>
    );
};

export default BarChart;




