// NationalIdUploadModal.js
import React, { useState } from 'react';
import { Modal } from 'antd';
import { IoCloudUploadOutline } from 'react-icons/io5';

const NationalIdUploadModal = ({ visible, onClose, onFileChange, }) => {
    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            onFileChange(file); // Update file in parent
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Preview the image
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Modal open={visible} footer={null} onCancel={onClose}>
            <div className="upload-dropzone" onClick={() => document.getElementById('nationalIdFileInput').click()}>
                <IoCloudUploadOutline style={{ fontSize: '2em', color: '#ccc' }} />
                <p>Upload National ID</p>
                <input
                    type="file"
                    id="nationalIdFileInput"
                    accept=".jpg, .png"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
            <p>JPG or PNG, up to 5 MB</p>
            {imagePreview && <img src={imagePreview} alt="National ID Preview" style={{ width: '100px', height: '100px' }} />}
            <div className="upload-actions">
                <button className="upload-btn" onClick={onClose}>Upload</button>
                <button className="cancel-btn" onClick={onClose}>Cancel</button>
            </div>
        </Modal>
    );
};

export default NationalIdUploadModal;
