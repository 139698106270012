import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import UserSidebar from './UserSidebar';
import Loader from '../../components/Loader';
import SignLogo from "../../assets/images/logo-w.png";
import UserNav from './UserNav';
import Cookies from "js-cookie";
import axios from 'axios';
import { toast } from 'react-toastify';
import { currencyOptions } from '../admin/currencyOptions';
import jsPDF from "jspdf";
import "jspdf-autotable";
const { Option } = Select;

const Reports = () => {
    const [loading, setLoading] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [allTransactions, setAllTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [status, setStatus] = useState('all');
    const [transactionId, setTransactionId] = useState('');
    const [userData, setUserData] = useState("");

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getCurrencyFlag = (currency) => {
        const currencyData = currencyOptions.find(option => option.value === currency);
        return currencyData ? currencyData.flag : null;
    };

    const authData = Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : null;
    const token = Cookies.get("token");


    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/kyc/user-kyc/${authData.user._id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (data?.success) {

                    setUserData(data.kyc);

                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.log(error);

            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [authData.user._id, token]);
    // Fetch Transactions
    useEffect(() => {
        const fetchTransactionsData = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/transaction/user-transactions/${authData.user._id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data?.success) {
                    const sortedTransactions = data.transactions.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setAllTransactions(sortedTransactions);
                    setFilteredTransactions(sortedTransactions);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchTransactionsData();
    }, []);

    // Filter Logic
    useEffect(() => {
        let filtered = [...allTransactions];

        // Date Range Filter
        if (startDate && endDate) {
            filtered = filtered.filter(transaction => {
                const transactionDate = new Date(transaction.date);
                const adjustedEndDate = new Date(endDate);
                adjustedEndDate.setDate(adjustedEndDate.getDate() + 1); // Move to the next day
                return transactionDate >= new Date(startDate) && transactionDate < adjustedEndDate;
            });
        }


        // Type Filter
        if (selectedTypes.length > 0) {
            filtered = filtered.filter(transaction => selectedTypes.includes(transaction.type));
        }

        // Asset Filter
        if (selectedAssets.length > 0) {
            filtered = filtered.filter(transaction => selectedAssets.includes(transaction.currency));
        }

        // Status Filter
        if (status !== 'all') {
            const statusValue = parseInt(status, 10);
            filtered = filtered.filter(transaction => transaction.status === statusValue);
        }

        // Transaction ID Search
        if (transactionId) {
            filtered = filtered.filter(transaction => transaction._id.includes(transactionId));
        }

        setFilteredTransactions(filtered);
    }, [startDate, endDate, selectedTypes, selectedAssets, status, transactionId, allTransactions]);

    const uniqueTypes = [...new Set(allTransactions.map(transaction => transaction.type))];
    const uniqueAssets = [...new Set(allTransactions.map(transaction => transaction.currency))];

    const clearDateFilters = () => {
        setStartDate('');
        setEndDate('');
    };

    const exportToPdf = () => {
        const doc = new jsPDF();

        const image = new Image();
        image.src = SignLogo;

        image.onload = () => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const leftMargin = 14;

            // Header Background
            const headerHeight = 30;
            doc.setFillColor("#F0F0F0");
            doc.rect(0, 0, pageWidth, headerHeight, "F");

            // Logo
            const imageWidth = 50;
            const imageHeight = (imageWidth * image.height) / image.width;
            const logoX = leftMargin + 2;
            const logoY = (headerHeight - imageHeight) / 2;
            doc.addImage(image, "PNG", logoX, logoY, imageWidth, imageHeight);

            // Bank Name and Address
            const textLeftMargin = logoX + imageWidth + 10;
            doc.setFontSize(16);
            doc.setTextColor("#0E2340");
            doc.text("Premier Quantitative Strategies (PQS) SPC FUND", textLeftMargin, logoY + 6);

            doc.setFontSize(12);
            doc.text("39 Hill St, London W1J 5NA", textLeftMargin, logoY + 14);

            const sectionStartY = headerHeight + 10; // Unified start position
            const lineSpacing = 6;
            doc.setFontSize(12);

            // "To" Details section
            let yPosition = sectionStartY;
            const toDetails = [
                userData?.firstName + " " + userData?.lastName || "No Name",
                userData?.address || "No Address",
                userData?.street || "No Street",
                userData?.nationality || "No Nationality",
            ];

            toDetails.forEach((line) => {
                doc.text(line, leftMargin, yPosition);
                yPosition += lineSpacing;
            });

            // Statement Date and Period Covered section
            const todayDate = new Date().toLocaleDateString("en-GB");
            let calculatedStartDate = startDate || (filteredTransactions.length > 0 ? filteredTransactions[filteredTransactions.length - 1]?.date : "N/A");
            let calculatedEndDate = endDate || (filteredTransactions.length > 0 ? filteredTransactions[0]?.date : "N/A");

            if (calculatedStartDate !== "N/A") {
                calculatedStartDate = new Date(calculatedStartDate).toLocaleDateString('en-GB');
            }
            if (calculatedEndDate !== "N/A") {
                calculatedEndDate = new Date(calculatedEndDate).toLocaleDateString('en-GB');
            }

            const statementInfoX = pageWidth / 2 + 10;

            // Aligning the statement section at the same height as the "To" section start
            doc.text(`Statement Date: ${todayDate}`, statementInfoX, sectionStartY);
            doc.text(`Period Covered: ${calculatedStartDate} - ${calculatedEndDate}`, statementInfoX, sectionStartY + lineSpacing);


            // Separator Line
            doc.setDrawColor("#0E2340");
            doc.setLineWidth(0.5);
            const lineStartY = yPosition;
            doc.line(leftMargin, lineStartY, pageWidth - leftMargin, lineStartY);

            // Account Summary Section
            const summaryStartY = lineStartY + 10;

            // Bold font for "ACCOUNT SUMMARY"
            doc.setFont(undefined, "bold");
            doc.setFontSize(16);
            doc.text("ACCOUNT SUMMARY", leftMargin, summaryStartY);
            // Reset font style for other text
            doc.setFont(undefined, "normal");
            doc.setFontSize(12);
            // Add extra margin below "ACCOUNT SUMMARY"
            const marginAfterSummary = 8;

            doc.text(
                `Balance on ${new Date().toLocaleDateString("en-GB", { month: "long", day: "numeric" })}: ${filteredTransactions[filteredTransactions.length - 1]?.closingBalance || 0} BTC`,
                leftMargin,
                summaryStartY + marginAfterSummary
            );
            doc.text(`Total Money In: ${totalPaidIn || 0} BTC`, leftMargin, summaryStartY + marginAfterSummary + 6);
            doc.text(`Total Money Out: ${totalPaidOut || 0} BTC`, leftMargin, summaryStartY + marginAfterSummary + 12);
            doc.text(`Total Fee: ${totalFee || 0} BTC`, leftMargin, summaryStartY + marginAfterSummary + 18);

            // Transactions Table
            const tableStartY = summaryStartY + 40;
            doc.autoTable({
                head: [["Date", "Type", "Debit", "Credit", "Transaction ID", "Balance"]],
                body: filteredTransactions.map((txn) => [
                    new Date(txn.date).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    }),
                    txn.type,
                    txn.type !== "Deposit" && txn.amount ? `- ${txn.amount} ${txn.currency}` : "",
                    txn.type === "Deposit" && txn.amount ? `+ ${txn.amount} ${txn.currency}` : "",
                    txn._id,
                    txn.closingBalance ? txn.closingBalance.toFixed(2) + " " + txn.currency : "",
                ]),
                startY: tableStartY,
                styles: { fontSize: 10 },
                headStyles: {
                    fillColor: "#0E2340",
                    textColor: "#FFFFFF",
                    fontStyle: "bold",
                },
                bodyStyles: { textColor: "#0E2340" },
                didParseCell: (data) => {
                    if (data.column.index === 2 && data.cell.raw?.startsWith("-")) {
                        data.cell.styles.textColor = "#FF0000";
                    }
                    if (data.column.index === 3 && data.cell.raw?.startsWith("+")) {
                        data.cell.styles.textColor = "#00AA00";
                    }
                },
            });

            // Footer
            const footerY = doc.internal.pageSize.getHeight() - 20;
            doc.setFontSize(8);
            const footerText = "The Premier Quantitative Strategies SP Fund is our main investment vehicle which is an open-ended mutual fund based in the Cayman Islands and regulated by the Cayman Island Monetary Authority (CIMA). The Premier Quantitative Strategies (PQS) SP Fund is only available to institutional investors and high net worth individuals who can be classified as professional investors. The Offering Memorandum is the only authorized document for the offering of shares in the PQS SP FUND. Its distribution is subject to the particular laws and regulations of the jurisdictions in which a potential investor resides as well as the categorization of the potential investor as a professional client in accordance with FCA rules.";

            doc.text(footerText, leftMargin, footerY, { maxWidth: pageWidth - 20 }); // 20 accounts for left & right margins


            // Save PDF
            doc.save("transaction-report.pdf");

        };

        image.onerror = () => {
            console.error("Failed to load logo image.");
        };
    };

    const totalPaidIn = filteredTransactions
        .filter(txn => txn.type === "Deposit")
        .reduce((sum, txn) => sum + txn.amount, 0);


    const totalPaidOut = filteredTransactions
        .filter(txn => txn.type !== "Deposit")
        .reduce((sum, txn) => sum + txn.amount, 0);

    const totalFee = filteredTransactions
        .filter(txn => txn.type !== "Deposit" && txn.type !== "Withdrawal")
        .reduce((sum, txn) => sum + txn.amount, 0);


    return (
        <>
            <div className="dashboard-container"></div>
            {loading && <Loader />}
            <UserSidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {/* Main Content */}
            <div className={`dashboard-main ${sidebarOpen ? 'shifted' : ''}`}>
                <UserNav title={"Reports"} />
                <div className="dashboard-content">
                    <div className="reports-main">
                        <div className="filters-main">
                            <div className="date-filter-main">
                                <div className="date-filter">
                                    <label className='bc-clr mb-3'>Date Range</label>
                                    <div className="d-flex align-items-center gap-3">
                                        <input
                                            type="date"
                                            className='filter-inp w-50'
                                            value={startDate}
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                        <input
                                            type="date"
                                            className='filter-inp w-50'
                                            value={endDate}
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                        <button
                                            className="clear-btn"
                                            onClick={clearDateFilters}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <button
                                    className="download-pdf"
                                    onClick={() => exportToPdf()}
                                >
                                    Download PDF
                                </button>
                            </div>

                            <div className="other-filter mt-3">
                                <div className="other-filter-sub">
                                    <label className='bc-clr'>Type</label>
                                    <Select
                                        mode="multiple"
                                        placeholder="Select Type(s)"
                                        value={selectedTypes}
                                        onChange={setSelectedTypes}
                                    >
                                        {uniqueTypes.map((type) => (
                                            <Option key={type} value={type}>{type}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="other-filter-sub">
                                    <label className='bc-clr'>Asset</label>
                                    <Select
                                        mode="multiple"
                                        placeholder="Select Asset(s)"
                                        value={selectedAssets}
                                        onChange={setSelectedAssets}
                                    >
                                        {uniqueAssets.map((asset) => (
                                            <Option key={asset} value={asset}>{asset}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="other-filter-sub">
                                    <label className='bc-clr'>Status</label>
                                    <select
                                        className='filter-inp'
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option value="all">All</option>
                                        <option value="0">Pending</option>
                                        <option value="1">Completed</option>
                                        <option value="2">Failed</option>
                                    </select>
                                </div>
                                <div className="other-filter-sub">
                                    <label className='bc-clr'>Transaction ID</label>
                                    <input
                                        type='text'
                                        className='filter-inp'
                                        placeholder='Search'
                                        value={transactionId}
                                        onChange={(e) => setTransactionId(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            {filteredTransactions.length > 0 ? (
                                <div className="reports-table-wrapper">
                                    <table className='reports-table table-responsive'>
                                        <thead>
                                            <tr>
                                                <th>Date/Time</th>
                                                <th>Debit/Credit</th>
                                                <th>Network</th>
                                                <th>Type</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Transaction Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredTransactions.map((transaction) => (
                                                <tr key={transaction._id} className="transaction-row">
                                                    <td>
                                                        {new Date(transaction.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}, <br />
                                                        {new Date(transaction.date).toLocaleTimeString('en-GB')}
                                                    </td>
                                                    <td>
                                                        {getCurrencyFlag(transaction.currency) && (
                                                            <img
                                                                src={getCurrencyFlag(transaction.currency)}
                                                                alt={transaction.currency}
                                                                style={{ width: '20px', marginRight: '5px' }}
                                                            />
                                                        )}
                                                        {transaction.currency}
                                                    </td>
                                                    <td>-</td>
                                                    <td className='text-capitalize'>{transaction.type}</td>
                                                    <td>{transaction.amount}</td>
                                                    <td>
                                                        <div className={
                                                            transaction.status === 0
                                                                ? "text-warning"
                                                                : transaction.status === 1
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                        }>
                                                            {transaction.status === 0
                                                                ? "Pending"
                                                                : transaction.status === 1
                                                                    ? "Completed"
                                                                    : "Failed"}
                                                        </div>
                                                    </td>
                                                    <td>{transaction._id}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="no-data-found">
                                    No Data Found
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Reports;
