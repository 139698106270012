import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Input } from 'antd';
import Sidebar from './Sidebar';
import { FaBarsStaggered } from 'react-icons/fa6';
import axios from 'axios';
import AdminNav from './AdminNav';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { FiEdit } from "react-icons/fi";
import Cookies from "js-cookie";
const KycDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [kyc, setKyc] = useState("");
    const [loading, setLoading] = useState(false);
    const [isRejectModalVisible, setRejectModalVisible] = useState(false);
    const [rejectReason, setRejectReason] = useState("");

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const token = Cookies.get("token");
    useEffect(() => {
        const fetchKycData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/kyc/single-kyc/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data?.success) setKyc(data.kyc);
            } catch (error) {
                console.error(error);
            }
        };
        fetchKycData();
    }, [id]);

    const handleApprove = async () => {
        setLoading(true);
        try {
            const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/kyc/${id}`, {
                status: 1
            });
            if (res.data.success) {
                toast.success("KYC approved successfully");
                navigate("/dashboard/admin/All-kyc");
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to approve KYC. Try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleReject = () => setRejectModalVisible(true);

    const submitRejection = async () => {
        setLoading(true);
        try {
            const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/kyc/${id}`, {
                status: 2,
                reason: rejectReason
            });
            if (res.data.success) {
                toast.success("KYC rejected successfully");
                setRejectModalVisible(false);
                navigate("/dashboard/admin/All-kyc");

            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to reject KYC. Try again.");
        } finally {
            setLoading(false);
            setRejectModalVisible(false);
            setRejectReason("");
        }
    };

    return (
        <>
            <div className="wrapper d-flex align-items-stretch">
                {loading && <Loader />}
                <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
                <div id="content" className="px-2 pt-3">
                    <button type="button" onClick={toggleSidebar} className="bars-btn">
                        <FaBarsStaggered />
                    </button>
                    <AdminNav />

                    <div className="shadow-lg rounded-3 p-4 deposit-card">
                        <h4 className="mt-3 text-uppercase">{kyc.identityType} Information</h4>
                        <label>{kyc.identityType} KYC Application</label>
                        <div className="border my-2 rounded-2 px-3 py-2">
                            <label className='d-flex justify-content-between mb-2'>
                                <span>Names :</span>
                                <span>{kyc.firstName + " " + kyc.lastName}</span>
                            </label>
                            <label className='d-flex justify-content-between mb-2'>
                                <span>Email :</span>
                                <span>{kyc.userEmail}</span>
                            </label>
                            <label className='d-flex justify-content-between mb-2'>
                                <span>Nationality :</span>
                                <span>{kyc.nationality}</span>
                            </label>
                            <label className='d-flex justify-content-between mb-2'>
                                <span>Address :</span>
                                <span>{kyc.address + kyc.street}</span>
                            </label>
                            <label className='d-flex justify-content-between mb-2'>
                                <span>Postal Code :</span>
                                <span>{kyc.postal}</span>
                            </label>
                        </div>
                        <div className="px-3">
                            <label className='d-flex justify-content-between my-2'>
                                <span>Proof of Identity</span>
                                <Link target='_blank' to={kyc.idCardImage || kyc.passportImage}><FiEdit /></Link>
                            </label>
                            <label className='d-flex justify-content-between my-2'>
                                <span>Proof of Residency</span>
                                <Link target='_blank' to={kyc.proofOfResidence || kyc.bankStatement || kyc.utilityBill || kyc.localAuthorityStatement}><FiEdit /></Link>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button onClick={handleApprove} className='text-success bg-transparent border-0 mx-3'>APPROVE</button>
                            <button onClick={handleReject} className='text-danger bg-transparent border-0 mx-3'>REJECT</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Rejection Modal */}
            <Modal
                title="Reject KYC"
                open={isRejectModalVisible}
                onOk={submitRejection}
                onCancel={() => setRejectModalVisible(false)}
                okText="Submit"
                cancelText="Cancel"
            >
                <Input.TextArea
                    rows={4}
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    placeholder="Enter the reason for rejection"
                />
            </Modal>
        </>
    );
};

export default KycDetails;
