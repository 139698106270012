import React, { useState } from 'react';
import UserSidebar from './UserSidebar';
import UserNav from './UserNav';
import Loader from '../../components/Loader';

const UserWithdrawals = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    return (
        <>
            <div
                className="dashboard-container"></div>
            {loading && <Loader />}
            <UserSidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {/* Main Content */}
            <div className={`dashboard-main ${sidebarOpen ? 'shifted' : ''}`}>
                <UserNav title={"Withdrawals"} />
                <div className="dashboard-content" >
                </div>
            </div>
        </>
    );
};

export default UserWithdrawals;