import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import './sidebar.css';
import SignLogo from "../../assets/images/logo-w.png";
import { MdDashboardCustomize } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { BsGraphUp } from "react-icons/bs";
import { LuRepeat } from "react-icons/lu";
import { PiHandWithdraw } from "react-icons/pi";
import { GoPlus } from "react-icons/go";


const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
    const location = useLocation(); // to get the current URL path
    const [activePath, setActivePath] = useState('');

    useEffect(() => {
        // Update activePath whenever the location changes
        setActivePath(location.pathname);
    }, [location]);


    return (
        <nav id="sidebar" className={sidebarOpen ? "active" : ""}>
            <div className="custom-menu">
                <button type="button" id="closeSidebar" onClick={toggleSidebar}>
                    <IoClose />
                </button>
            </div>

            <div className="py-2">
                <div className="my-2">
                    <Link to="/dashboard">
                        <img className='sidebar-logo' src={SignLogo} alt="logo" />
                    </Link>
                </div>

                <ul className="list-unstyled components mb-5">
                    <li className={activePath === '/dashboard/admin' ? 'active-sidebar' : ''}>
                        <Link to="/dashboard/admin">
                            <MdDashboardCustomize className="mr-3" /> Dashboard
                        </Link>
                    </li>
                    <li className={activePath === '/dashboard/admin/All-kyc' ? 'active-sidebar' : ''}>
                        <Link to="/dashboard/admin/All-kyc">
                            <IoWalletOutline className="mr-3" /> Pending KYC Approvals
                        </Link>
                    </li>
                    <li className={activePath === '/dashboard/admin/transactions' ? 'active-sidebar' : ''}>
                        <Link to="/dashboard/admin/transactions">
                            <IoWalletOutline className="mr-3" /> Transactions
                        </Link>
                    </li>

                    <li className={activePath === '/dashboard/admin/deposit' ? 'active-sidebar' : ''}>
                        <Link to="/dashboard/admin/deposit">
                            <LuRepeat className="mr-3" /> Deposit
                        </Link>
                    </li>
                    <li className={activePath === '/settings/withdrawals' ? 'active-sidebar' : ''}>
                        <Link to="/settings/withdrawals">
                            <PiHandWithdraw className="mr-3" /> Withdrawals
                        </Link>
                    </li>

                    <li className={activePath === '/settings/admin-users' ? 'active-sidebar' : ''}>
                        <Link to="/settings/admin-users">
                            <GoPlus className="mr-3" /> Admin Users
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;
