import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { FaBarsStaggered } from 'react-icons/fa6';
import Cookies from 'js-cookie';
import axios from 'axios';
import AdminNav from './AdminNav';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Loader from '../../components/Loader';
import 'currency-flags/dist/currency-flags.min.css';
import Select from 'react-select';
import { currencyOptions } from './currencyOptions';
import successSound from '../../assets/tune.mp3';
const DepositMoney = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    // State variables
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [user, setUser] = useState({});
    const [paymentMethod, setPaymentMethod] = useState('');
    const [amount, setAmount] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const [error, setError] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isHedgingChecked, setIsHedgingChecked] = useState(false);
    const [hedging, setHedging] = useState("");
    const [sales, setSales] = useState("");
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    const handleCheckboxSalesChange = (e) => {
        setIsChecked(e.target.checked);
    };
    const handleCheckboxHedgingChange = (e) => {
        setIsHedgingChecked(e.target.checked);
    };
    const handleCurrencyChange = (option) => {
        setSelectedCurrency(option.value);
        setSelectedAsset(option.value);
    };
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const cryptoOptions = [
        {
            value: 'BTC',
            label: 'Bitcoin',
            image: 'https://assets.coingecko.com/coins/images/1/thumb/bitcoin.png'
        },
        {
            value: 'ETH',
            label: 'Ethereum',
            image: 'https://assets.coingecko.com/coins/images/279/thumb/ethereum.png'
        },
        {
            value: 'USDT',
            label: 'Tether',
            image: 'https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png'
        },
        {
            value: 'BNB',
            label: 'Binance Coin',
            image: 'https://assets.coingecko.com/coins/images/825/thumb/binance-coin-logo.png'
        },
        {
            value: 'USDC',
            label: 'USD Coin',
            image: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png'
        },
        {
            value: 'XRP',
            label: 'Ripple',
            image: 'https://assets.coingecko.com/coins/images/44/thumb/xrp-symbol-white-128.png'
        },
        {
            value: 'ADA',
            label: 'Cardano',
            image: 'https://assets.coingecko.com/coins/images/975/thumb/cardano.png'
        },
        {
            value: 'SOL',
            label: 'Solana',
            image: 'https://assets.coingecko.com/coins/images/4128/thumb/solana.png'
        },
        {
            value: 'DOGE',
            label: 'Dogecoin',
            image: 'https://assets.coingecko.com/coins/images/5/thumb/dogecoin.png'
        },
        {
            value: 'MATIC',
            label: 'Polygon',
            image: 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png'
        }
    ];



    // Fetch user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/auth/get-user/${id}`);
                if (data?.success) setUser(data.user);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
    }, [id]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!paymentMethod || !amount || (paymentMethod === 'crypto' && !selectedAsset) || (paymentMethod === 'Fiat' && !selectedCurrency)) {
            toast.error('Please fill all required fields.');
            return;
        } else if (!sales || !hedging) {
            toast.error(`Sales and Hedging Fields are required`);
            return;
        }
        setShowConfirmation(true);
    };

    const handleConfirm = () => {
        setShowConfirmation(false);
        setShowPinModal(true);
    };

    const handlePinSubmit = async (e) => {
        const name = user.name;
        const email = user.email;
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API}/api/v1/transaction/new`, {
                name, email,
                userId: id,
                amount,
                paymentMethod,
                currency: selectedCurrency || selectedAsset,
                pinCode,
                salesChecked: isChecked,
                salesPercentage: isChecked ? sales : 0,
                hedgingChecked: isHedgingChecked,
                hedgingPercentage: isHedgingChecked ? hedging : 0,
            });

            if (data.success) {
                toast.success(data.message);
                resetForm();
                setShowPinModal(false);
                const audio = new Audio(successSound);
                audio.play();
                navigate("/dashboard/admin/transactions");
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Deposit failed:', error);
            toast.error(error.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setAmount('');
        setPaymentMethod('');
        setSelectedAsset('');
        setSelectedCurrency('');
        setPinCode('');
    };
    const formatOptionLabel = ({ label, flag }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={flag} alt="" style={{ width: 20, marginRight: 10 }} />
            <span>{label}</span>
        </div>
    );
    return (
        <>
            <div className="wrapper d-flex align-items-stretch">
                {loading && <Loader />}
                <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
                <div id="content" className="px-2 pt-3">
                    <button type="button" onClick={toggleSidebar} className="bars-btn">
                        <FaBarsStaggered />
                    </button>
                    <AdminNav />

                    <div className="shadow-lg rounded-3 p-3 deposit-card">
                        <h4 className="text-center mb-3">Admin Deposit</h4>
                        <form>
                            <div className="mb-3">
                                <label>Username</label>
                                <input type="text" className="form-control" disabled value={user.name || ''} />
                            </div>
                            <div className="mb-3">
                                <label>Email</label>
                                <input type="text" className="form-control" disabled value={user.email || ''} />
                            </div>
                            <div className="mb-3">
                                <label>Payment Method</label>
                                <select
                                    className="form-control"
                                    value={paymentMethod}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    required
                                >
                                    <option value="">Select Method</option>
                                    <option value="Fiat">Fiat</option>
                                    <option value="Crypto">Crypto</option>
                                </select>
                            </div>

                            {paymentMethod === 'Crypto' && (
                                <div className="mb-3">
                                    <label> Asset</label>
                                    <div className="mb-3">
                                        <Select
                                            options={cryptoOptions}
                                            value={cryptoOptions.find(option => option.value === selectedAsset)} //  
                                            onChange={handleCurrencyChange}
                                            formatOptionLabel={formatOptionLabel}
                                            placeholder="Select Asset"
                                            isSearchable
                                        />
                                    </div>

                                </div>
                            )}
                            {paymentMethod === 'Fiat' && (
                                <div className="mb-3">
                                    <label> Currency</label>
                                    <div className="mb-3">
                                        <Select
                                            options={currencyOptions}
                                            value={currencyOptions.find(option => option.value === selectedCurrency)}
                                            onChange={handleCurrencyChange}
                                            formatOptionLabel={formatOptionLabel}
                                            placeholder="Select Currency"
                                            isSearchable
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label>Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex">
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxSalesChange}
                                        required
                                    />
                                    <label className='form-label ms-3'>Sales Charge</label>

                                </div>

                                <input type="number" disabled={!isChecked} placeholder='Sales Charge %' value={sales}
                                    onChange={(e) => setSales(e.target.value)} className='form-control' />
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex">
                                    <input
                                        type="checkbox"
                                        checked={isHedgingChecked}
                                        onChange={handleCheckboxHedgingChange}
                                        required
                                    />
                                    <label className='form-label ms-3'>Hedging Fees</label>
                                </div>

                                <input type="number" placeholder='Hedging Fees %' value={hedging} disabled={!isHedgingChecked}
                                    onChange={(e) => setHedging(e.target.value)} className='form-control' />
                            </div>

                            <div className="text-center">
                                <button type="button" onClick={handleFormSubmit} className="btn btn-primary my-3">
                                    Deposit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            {showConfirmation && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ padding: "0px" }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Transaction</h5>
                                <button type="button" className="btn-close" onClick={() => setShowConfirmation(false)}></button>
                            </div>
                            <div className="modal-body" >
                                <div className="d-flex flex-column align-items-start justify-content-start">
                                    <h6> Name:  {user.name}</h6>
                                    <h6> Email:  {user.email}</h6>
                                    <h6> Payment Method:  {paymentMethod}</h6>
                                    <h6> {paymentMethod === 'crypto' ? 'Asset' : 'Currency'}:  {paymentMethod === 'crypto' ? selectedAsset : selectedCurrency}</h6>
                                    <h6> Amount:  {amount}</h6>
                                    <h6> Sales Fee:  {sales}%</h6>
                                    <h6> Hedging Fee:  {hedging}%</h6>

                                </div>
                                <div className="text-end">
                                    <button className="btn btn-secondary mx-2" onClick={() => setShowConfirmation(false)}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary mx-2" onClick={handleConfirm}>
                                        Confirm
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            )}

            {/* Pin Code Modal */}
            {showPinModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ padding: "0px" }}>
                            <div className="modal-header">
                                <h5 className="modal-title">Enter Pin Code</h5>
                                <button type="button" className="btn-close" onClick={() => setShowPinModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group mb-3">
                                    <input
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        className="form-control"
                                        value={pinCode}
                                        onChange={(e) => setPinCode(e.target.value)}
                                        placeholder="Enter Pin Code"
                                        required
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>

                                <div className="text-end mt-3">
                                    <button className="btn btn-secondary mx-2" onClick={() => setShowPinModal(false)}>
                                        Cancel
                                    </button>
                                    <button type='submit' className="btn btn-primary mx-2" onClick={handlePinSubmit}>
                                        Verify
                                    </button>
                                </div>

                                {error && <p className="text-danger mt-2">{error}</p>}
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DepositMoney;
