import React from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

const LineChart = () => {
    const today = dayjs().startOf('day');

    // Initialize empty data for NAV for the past 5 days including today
    const navData = [];

    for (let i = 4; i >= 0; i--) {
        const day = today.subtract(i, 'day').startOf('day');
        navData.push({ x: day.toDate(), y: 0 }); // Default y value is 0
    }

    const chartData = {
        series: [
            { name: 'NAV', data: navData },
        ],
        options: {
            chart: {
                id: 'last5days',
                height: 350,
                type: 'line',
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            colors: ['#0E2340'],
            dataLabels: { enabled: false },
            stroke: { curve: 'smooth' },
            markers: { size: 5 },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false,
                    format: 'MMM dd', // Show only the month and day
                },
                tickAmount: 5, // Ensure only 5 ticks are displayed for the 5 days
            },
            yaxis: {
                labels: {
                    formatter: (value) => Math.round(value).toLocaleString(), // Numeric values only
                },
            },
            legend: { show: false }, // Disable the legend below the chart
        },
    };

    return (
        <div>
            <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
        </div>
    );
};

export default LineChart;
