import React from 'react';
import UserSidebar from './UserSidebar';

const Fees = () => {
    return (
        <>
            <UserSidebar />
        </>
    );
};

export default Fees;